@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}
